<template>
  <v-form ref="formFaxiMetaProgressiva" v-model="valid">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          :disabled="!!form.quantidadeAte"
          v-model="form.quantidadeDe"
          label="Quantidade de:"
          type="number"
          :rules="[rules.required, rules.maiorZero, rules.intervalFaixa]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          :disabled="!form.quantidadeDe"
          v-model="form.quantidadeAte"
          type="number"
          label="Quantidade até:"
          :rules="[
            rules.maiorQuantidadeDe,
            rules.intervalFaixa,
            rules.intervalFaixaInput
          ]"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <vuetify-money
          v-model.number="form.valorBonus"
          label="Valor Bônus"
          @input="checkBonusMaximo($event)"
          :error-messages="erroBonusMaximo"
          :options="optionsVuetifyMoney"
          :rules="[rules.required, rules.maiorZero]"
          @keydown.enter="submitFormFaixaMetaProgressiva()"
        ></vuetify-money>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="close()">
        Cancelar
      </v-btn>
      <v-btn
        :disabled="!valid"
        color="blue darken-1"
        text
        @click.enter="submitFormFaixaMetaProgressiva()"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>
<script>
import campanhaService from "@/services/campanha";
import { mapGetters } from "vuex";

export default {
  name: "form-meta-progressiva",

  props: {
    item: {
      required: true
    }
  },

  data() {
    return {
      bonificacaoMaxima: null,
      erroBonusMaximo: null,

      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 6,
        precision: 2
      },
      intervalo: [],
      form: {
        quantidadeDe: null,
        quantidadeAte: null,
        valorBonus: null
      },
      rules: {
        required: value => !!value || "Valor obrigatorio",
        maiorZero: value =>
          parseFloat(value) > 0 || "O valor der ser maior que zero",
        maiorQuantidadeDe: value => {
          return (
            value >= parseFloat(this.form.quantidadeDe) ||
            "O valor deve ser maior ou igual ao inicial"
          );
        },
        intervalFaixa: value => {
          let faixa = this.validationIntervalFaixa(value);
          return (
            faixa == undefined ||
            `Há um faixa cadastrada com  intervalo de ${faixa.qtde_de} até ${faixa.qtde_ate}`
          );
        },
        intervalFaixaInput: () => {
          let faixa = this.validationItervalInput();
          return (
            faixa == undefined ||
            `O intervalo escolhido  possui a faixa ${faixa.qtde_de} até ${faixa.qtde_ate} `
          );
        }
      },
      valid: false
    };
  },

  computed: {
    ...mapGetters({
      getFaixasMetaProgressiva: "campanha/getFaixasMetaProgressiva"
    })
  },

  methods: {
    resetForm() {
      this.form = {
        quantidadeDe: null,
        quantidadeAte: null,
        valorBonus: null
      };
    },

    close() {
      this.resetValidation();
      this.resetForm();

      this.$emit("close");
    },

    resetValidation() {
      this.$refs.formFaxiMetaProgressiva.resetValidation();
    },

    validationIntervalFaixa(value) {
      let item = this.getFaixasMetaProgressiva(this.item.id_campanha_funcao);

      return item.find(faixa => {
        let status = this.interval(
          value,
          parseFloat(faixa.qtde_de),
          parseFloat(faixa.qtde_ate)
        );

        if (status) {
          return faixa;
        }
      });
    },

    validationItervalInput() {
      let item = this.getFaixasMetaProgressiva(this.item.id_campanha_funcao);

      return item.find(faixa => {
        return (
          this.interval(
            parseFloat(faixa.qtde_ate),
            parseFloat(this.form.quantidadeDe),
            parseFloat(this.form.quantidadeAte)
          ) ||
          this.interval(
            parseFloat(faixa.qtde_de),
            parseFloat(this.form.quantidadeDe),
            parseFloat(this.form.quantidadeAte)
          )
        );
      });
    },

    interval(valueBusca, inico, fim) {
      return valueBusca >= inico && valueBusca <= fim;
    },

    async submitFormFaixaMetaProgressiva() {
      try {
        const metaProgressiva = {
          id_campanha_funcao: this.item.id_campanha_funcao,
          id_campanha: this.item.id_campanha,
          id_funcao: this.item.id_funcao,
          id_indicador: this.item.id_indicador,
          qtde_de: this.form.quantidadeDe,
          qtde_ate: this.form.quantidadeAte,
          valor_bonus: this.form.valorBonus
        };
        await campanhaService.postMetaProgressiva(metaProgressiva);

        this.resetForm();
        this.resetValidation();
        this.$notify({ type: "success", text: "Faixa cadastrada com sucesso" });
        this.$emit("success");
      } catch (error) {
        this.$notify({ type: "error", text: "Erro ao cadastrar faixa" });
      }
    },

    async fechBonusMaximoProgressiva() {
      try {
        this.erroBonusMaximo = null;
        const { data } = await campanhaService.getBonificacaoMaxima({
          id_band: this.item.id_band,
          id_tipo_meta: "5"
        });
        this.bonificacaoMaxima = data.data[0];
      } catch (error) {
        console.error(error);
        this.$notify({
          text: "Erro ao buscar o Bonificação Máxima",
          type: "error"
        });
      }
    },

    checkBonusMaximo(valor) {
      if (valor > parseFloat(this.bonificacaoMaxima.valor_max)) {
        const valueCurrency = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(this.bonificacaoMaxima.valor_max);
        this.erroBonusMaximo = `Bonificação Limite é de ${valueCurrency}`;
      } else {
        this.erroBonusMaximo = null;
      }
    }
  },

  mounted() {
    this.fechBonusMaximoProgressiva();
  }
};
</script>
