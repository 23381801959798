<template>
  <v-card>
    <v-data-table :headers="headers" :items="funcoes" class="elevation-1">
      <template v-slot:top>
        <v-card-title> Funções Selecionadas para a Campanha </v-card-title>
      </template>
      <template v-slot:[`item.descricao`]="{ item }">
        {{ item.descricao | TitleCase }}
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon v-if="item.possuiMeta" color="success">mdi mdi-check</v-icon>
        <v-tooltip v-else top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="warning" v-bind="attrs" v-on="on"
              >mdi mdi-alert-circle
            </v-icon>
          </template>
          <span> Não possui metas cadastradas</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn v-if="onEdite" @click="cadastrar(item)" color="green" dark small
          ><v-icon class="mr-2">mdi-finance</v-icon> Cadastrar meta</v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "TableFuncoesPublicoAlvo",

  components: {
    BaseSegmento: () => import("@/components/shared/BaseSegmento")
  },

  props: {
    funcoes: Array,
    onEdite: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      const header = [
        {
          text: "Função",
          value: "descricao"
        },
        {
          text: "Segmento",
          value: "id_band"
        },
        {
          text: "status",
          value: "status"
        }
      ];

      if (this.onEdite) {
        header.push({
          text: "Ações",
          value: "action",
          align: "end"
        });
      }

      return header;
    }
  },
  methods: {
    cadastrar(item) {
      this.$emit("onCadastrarMeta", item);
    }
  }
};
</script>
