<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="70%"
    transition="dialog-transition"
  >
    <v-card>
      <v-toolbar color="grey" dark>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('close')" v-bind="attrs" v-on="on" icon>
              <v-icon color="error">mdi mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :page.sync="page"
        no-results-text="Dados não encontradoss"
        sort-by="meta_campanha"
        sort-desc
        :footer-props="{
          'items-per-page-text': 'Colaborador por pagina'
        }"
        class="mr-2 ml-2"
      >
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} de {{ items.pageStop }} Total
          {{ items.itemsLength }}
        </template>
        <template v-slot:[`item.colaborador`]="{ item }">
          {{ item.nome | TitleCase }}
        </template>
        <template v-slot:[`item.id_band`]="{ item }">
          <Segmento :segmento="item.id_band" />
        </template>
        <v-data-footer items-per-page-text="toa"> </v-data-footer>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {
    Segmento: () => import("@/components/shared/BaseSegmento")
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    dialog: Boolean
  },
  watch: {
    dialog(value) {
      return value;
    }
  },

  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
      headers: [
        {
          text: "Segmento",
          value: "id_band"
        },
        {
          text: "Empresa",
          value: "apelido"
        },
        {
          text: "Função",
          value: "cod_funcao",
          align: "end"
        },
        {
          text: "Descricao",
          value: "descricao"
        },
        {
          text: "Colaborador",
          value: "nome"
        },
        {
          text: "Indicador",
          value: "indicador"
        },
        {
          text: "Meta GMR",
          value: "meta_gmr",
          align: "end"
        },
        {
          text: "Meta",
          value: "meta_campanha",
          align: "end"
        },
        {
          text: "Bônus",
          value: "valor_bonus",
          align: "end"
        }
      ]
    };
  }
};
</script>
