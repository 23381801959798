import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes"
    })
  },
  methods: {
    ...mapActions({
      actionFuncoesPublicoAlvo: "campanha/actionFuncoesPublicoAlvo",
      fetchMetasCadastradas: "campanha/fetchMetasCadastradas",
      fetchCampanhasEquipes: "campanha/fetchCampanhasEquipes",
      fetchEquipes: "empresa/fetchEquipes",
      actionEmpresasParticipantes: "campanha/actionEmpresasParticipantes"
    }),

    initActionFuncaoPublicoAlvo() {
      const empresas = this.getEmpresasParticipantes.map(
        item => item.id_empresa
      );

      const empresasPerticipantes = empresas.toString();
      this.actionFuncoesPublicoAlvo({
        id_band: this.filtroBandeira || undefined,
        id_campanha: this.$route.params.idCampanha,
        id_empresa: empresasPerticipantes
      });
    },

    initActionFeatMetasCadastradas() {
      this.fetchMetasCadastradas(this.$route.params.idCampanha);
    },

    async initializeEmpresasParticipantes() {
      if (this.getEmpresasParticipantes.length) return;
      await this.actionEmpresasParticipantes(this.$route.params.idCampanha);
    },

    async buscaCampanhasEquipes() {
      this.initializeEmpresasParticipantes();
      this.dialog = false;
      try {
        const empresas = this.getEmpresasParticipantes.map(
          item => item.id_empresa
        );

        const empresasPerticipantes = empresas.toString();

        await this.fetchCampanhasEquipes({
          per_page: -1,
          id_campanha: this.$route.params.idCampanha
        });
        await this.fetchEquipes({
          per_page: -1,
          id_empresa: empresasPerticipantes,
          ativo: "S"
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: "Erro ao buscar empresas e equipes"
        });
      }
    }
  }
};
