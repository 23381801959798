<template>
  <div class="mt-4">
    <BaseTable
      :headers="headers"
      :items="getDataTable"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id_campanha_funcao"
      show-expand
      sort-by="cod_funcao"
      class="mb-5"
    >
      <template v-slot:top>
        <v-card-title> Metas Modelos </v-card-title>
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>

      <template v-slot:[`item.indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.perc_cresc`]="{ item }">
        <span v-if="item.perc_cresc">
          <v-icon color="green">mdi-finance</v-icon>
          {{ item.perc_cresc }}%
        </span>
        <span v-else>Não possui</span>
      </template>

      <template v-slot:[`item.maior_menor`]="{ item }">
        <span v-if="item.maior_menor === '>'">
          <v-icon class="mr-1" small color="green">mdi-arrow-up </v-icon>
          Maior
        </span>
        <span v-if="item.maior_menor === '<'">
          <v-icon class="mr-1" small color="blue">mdi-arrow-down </v-icon>
          Menor
        </span>
      </template>

      <template
        v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }"
      >
        <v-btn
          color="primary"
          small
          @click="
            expand(!isExpanded);
            vincularModelos(item, false);
          "
          >expandir</v-btn
        >
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <ListaModelosParticipantes
            :items="modelosParticipantesFuncao(item)"
          />
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="vincularModelos(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
          </template>
          <span>Vincular Modelo<br />a Campanha</span>
        </v-tooltip>
      </template>
    </BaseTable>

    <DraggableModeloCampanha
      :dialog="dialog"
      :campanha="campanha"
      :modeloNaoParticipantes="modeloNaoParticipantes"
      :modeloParticipantes="modeloParticipantes"
      @close="dialog = false"
      @atualizaModelosParticipantes="atualizaModelosParticipantes($event)"
      @atualizaModelosNaoParticipantes="atualizaModelosNaoParticipantes($event)"
    />
  </div>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import BaseTable from "@/components/shared/NewBaseTable";
import DraggableModeloCampanha from "./DraggableModeloCampanha";
import ListaModelosParticipantes from "./ListaModelosParticipantes";
import { mapGetters } from "vuex";
import currencyMixin from "@/mixins/currencyMixin";
import serviceCampanha from "@/services/campanha";
import serviceParametros from "@/services/parametros";

export default {
  name: "TableMetaModelos",

  components: {
    BaseSegmento,
    BaseTable,
    DraggableModeloCampanha,
    ListaModelosParticipantes
  },

  mixins: [currencyMixin],

  data() {
    return {
      headers: [
        { text: "Cód. Função", align: "start", value: "cod_funcao" },
        { text: "Função", value: "funcao" },
        { text: "segmento", value: "id_band" },
        { text: "Indicador", value: "indicador" },
        { text: "Tipo de Meta", value: "tipo_meta" },
        { text: "Base de Cálculo", value: "tipo_calculo" },
        { text: "Crescimento", value: "perc_cresc" },
        { text: "Prioridade do Indicador", value: "maior_menor" },
        { text: "Vincular Modelo", value: "actions", sortable: false, align: "end" },
        { text: "Meta/Bônus", value: "data-table-expand", align: "center" },
      ],
      dialog: false,
      campanha: {},
      modeloNaoParticipantes: [],
      modeloParticipantes: [],
      expanded: [],
      singleExpand: true
    };
  },

  computed: {
    ...mapGetters({
      getMetasCadastradas: "campanha/getMetasCadastradas",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes"
    }),

    getDataTable() {
      return this.getMetasCadastradas.filter(meta => meta.id_tipo_meta == 10);
    }
  },

  methods: {
    async atualizaModelosParticipantes(modelo) {
      const { data } = await serviceCampanha.getCampanhaModelo({
        per_page: -1,
        id_funcao: modelo.id_funcao,
        id_indicador: modelo.id_indicador,
        id_campanha: this.$route.params.idCampanha
      });
      this.modeloParticipantes = data.data;
    },

    async atualizaModelosNaoParticipantes(item) {
      const campanhaModelo = await serviceCampanha.getCampanhaModelo({
        per_page: -1,
        id_funcao: item.id_funcao,
        id_indicador: item.id_indicador,
        id_campanha: this.$route.params.idCampanha
      });

      const modelo = await serviceParametros.getProdutoModelo({
        per_page: -1,
        id_band: item.id_band,
        ativo: "S"
      });

      const participantes = campanhaModelo.data.data.map(
        item => item.id_produto
      );

      this.modeloNaoParticipantes = modelo.data.data.filter(
        item => !participantes.includes(String(item.id_produto))
      );
    },

    async vincularModelos(item, dialog = true) {
      try {
        this.campanha = {
          id_funcao: item.id_funcao,
          id_indicador: item.id_indicador
        };

        const campanhaModelo = await serviceCampanha.getCampanhaModelo({
          per_page: -1,
          id_funcao: item.id_funcao,
          id_indicador: item.id_indicador,
          id_campanha: this.$route.params.idCampanha
        });

        const modelo = await serviceParametros.getProdutoModelo({
          per_page: -1,
          id_band: item.id_band,
          ativo: "S"
        });
        this.dialog = dialog;

        this.modeloParticipantes = campanhaModelo.data.data.sort((a, b) =>
          parseInt(a.cod_modelo) > parseInt(b.cod_modelo) ? 1 : -1
        );

        const participantes = campanhaModelo.data.data.map(
          item => item.id_produto
        );

        this.modeloNaoParticipantes = modelo.data.data
          .filter(item => !participantes.includes(String(item.id_produto)))
          .sort((a, b) =>
            parseInt(a.cod_modelo) > parseInt(b.cod_modelo) ? 1 : -1
          );
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: "Erro ao buscar modelos"
        });
      }
    },

    modelosParticipantesFuncao({ id_funcao, id_indicador }) {
      const equipe = this.modeloParticipantes?.filter(
        item => item.id_funcao == id_funcao && item.id_indicador == id_indicador
      );
      return equipe;
    }
  }
};
</script>
