var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items.length)?_c('BaseTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"itemsPerPage":_vm.items.length,"paginate":false,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.individual",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.vincularColaborador(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular Colaborador"),_c('br'),_vm._v("a Campanha")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.listarColaborador(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar Colaboradores")])])]}},{key:"item.progressiva",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.vincularColaboradorProgressiva(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar Faixa")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.listarColaboradorProgressiva(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-search")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar Faixas")])])]}}],null,true)}):_vm._e(),_c('DraggableColaboradorCampanhaModelo',{attrs:{"dialog":_vm.dialog,"campanha":_vm.campanha,"colaboradoresNaoParticipantes":_vm.colaboradoresNaoParticipantes,"colaboradoresParticipantes":_vm.colaboradoresParticipantes},on:{"close":_vm.closeModal,"atualizaColaboradoresParticipantes":_vm.atualizaColaboradoresParticipantes,"atualizaColaboradoresNaoParticipantes":_vm.atualizaColaboradoresNaoParticipantes}}),_c('DraggableColaboradorCampanhaModeloProgressiva',{attrs:{"dialog":_vm.dialogProgressivo,"campanha":_vm.campanha,"colaboradoresNaoParticipantesProgressiva":_vm.colaboradoresNaoParticipantesProgressiva,"colaboradoresParticipantesProgressiva":_vm.colaboradoresParticipantesProgressiva},on:{"close":_vm.closeModalProgressiva,"atualizaColaboradoresParticipantesProgressiva":_vm.atualizaColaboradoresParticipantesProgressiva}}),_c('ModalColaboradorCampanhaModelo',{attrs:{"items":_vm.colaboradoresParticipantes,"dialog":_vm.dialogList},on:{"close":function($event){_vm.dialogList = false}}}),_c('ModalColaboradorCampanhaModeloProgressiva',{attrs:{"items":_vm.colaboradoresParticipantesProgressiva,"dialog":_vm.dialogListProgressiva},on:{"close":function($event){_vm.dialogListProgressiva = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }