<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    max-width="70%"
    transition="dialog-transition"
  >
    <v-card>
      <v-toolbar color="grey" dark>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('close')" v-bind="attrs" v-on="on" icon>
              <v-icon color="error">mdi mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
        </v-tooltip>
      </v-toolbar>
      <BaseTable
        :headers="headers"
        :items="items"
        :search="search"
        sort-by="meta_campanha"
        sort-desc
        class="mr-2 ml-2"
      >
        <template v-slot:[`item.id_band`]="{ item }">
          <Segmento :segmento="item.id_band" />
        </template>

        <template v-slot:[`item.colaborador`]="{ item }">
          {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
        </template>

        <template v-slot:[`item.indicador`]="{ item }">
          {{ item.id_indicador }} - {{ item.indicador }}
        </template>
      </BaseTable>
    </v-card>
  </v-dialog>
</template>
<script>
import Segmento from "@/components/shared/BaseSegmento";
import BaseTable from "@/components/shared/NewBaseTable";

export default {
  name: "ModalColaboradorCampanhaModelo",

  components: {
    Segmento,
    BaseTable
  },

  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      search: "",
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Função", value: "cod_funcao", align: "end" },
        { text: "Descricao", value: "funcao" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Indicador", value: "indicador" },
        { text: "Meta", value: "meta_campanha", align: "end" },
        { text: "Bônus", value: "valor_bonus", align: "end" }
      ]
    };
  }
};
</script>
