<template>
  <div>
    <v-simple-table class="mb-3 mt-2">
      <template v-slot:default v-if="items.length">
        <thead>
          <tr>
            <th class="text-left">
              Quantidade de
            </th>
            <th class="text-left">
              Quantidade até
            </th>
            <th class="text-left">
              Valor do Bônus
            </th>
            <th v-if="onEdite" class="text-left">
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.qtde_de }}</td>
            <td>{{ item.qtde_ate }}</td>
            <td>{{ item.valor_bonus | BrazilianCurrency }}</td>
            <td v-if="onEdite">
              <v-btn @click="deleteItem(item)" icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:default v-else>
        <v-alert border="bottom" colored-border type="warning" elevation="2">
          Metas progressivas devem ter no minimo duas faixas
        </v-alert>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import campanhaService from "@/services/campanha";
import currencyMixin from "@/mixins/currencyMixin";
export default {
  name: "lista-faixas-progressivas",

  mixins: [currencyMixin],

  props: {
    items: {
      required: true,
      type: Array
    },
    onEdite: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    async deleteItem(item) {
      try {
        await campanhaService.deleteMetaProgressiva(item.id_meta_progressiva);
        this.$notify({
          type: "warning",
          text: "Faixa progressiva excluida!"
        });

        this.$emit("delete");
      } catch (error) {
        this.$emit("delete");
        this.$notify({
          type: "error",
          text: "Erro ao exculir faixa!"
        });
      }
    }
  }
};
</script>
