var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"70%","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"grey","dark":""}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('BaseTable',{staticClass:"mr-2 ml-2",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"meta_campanha","sort-desc":""},scopedSlots:_vm._u([{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('Segmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.colaborador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_colaborador)+" - "+_vm._s(_vm._f("TitleCase")(item.colaborador))+" ")]}},{key:"item.indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_indicador)+" - "+_vm._s(item.indicador)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }