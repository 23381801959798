<template>
  <v-dialog v-model="dialog" persistent max-width="50%" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline"> Vincular Equipe </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Equipes Fora da
              Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodasEquipes()"
              :disabled="equipesNaoParticipantes.length == 0"
            >
              Adicionar todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area exclude-companies"
              :list="equipesNaoParticipantes"
              group="equipes"
              @change="moveAdded()"
            >
              <div
                class="v-list-item__content list"
                v-for="equipe in equipesNaoParticipantes"
                :key="equipe.id_equipe"
              >
                <div class="list-equipe-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${equipe.id_equipe} -`
                      }}</strong>
                      {{ equipe.nome_equipe }}
                    </v-row>
                    <BaseSegmento :segmento="equipe.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="addEquipesParcitipantes(equipe)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Equipes
              Participantes da Campanha
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodasEquipes()"
              :disabled="equipesParticipantes.length == 0"
            >
              Remover todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area include-companies"
              :list="equipesParticipantes"
              group="equipes"
              @change="moveRemove()"
            >
              <div
                class="v-list-item__content"
                v-for="equipe in equipesParticipantes"
                :key="equipe.id_equipe"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn
                      class="mr-4"
                      icon
                      @click="removeEquipesParticipantes(equipe)"
                    >
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${equipe.id_equipe} -`
                      }}</strong>

                      {{ equipe.nome_equipe }}
                    </v-row>
                    <BaseSegmento :segmento="equipe.id_band" />
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import serviceCampanha from "@/services/campanha";
import initMixin from "../../initMixin";
import { mapGetters } from "vuex";

export default {
  name: "DraggableEquipesCampanha",

  components: {
    draggable,
    BaseSegmento: () => import("@/components/shared/BaseSegmento.vue")
  },
  mixins: [initMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    dadosCampanha: {
      type: Object
    },

    equipesNaoParticipantes: {
      type: Array
    },

    equipesParticipantes: {
      type: Array
    }
  },

  data() {
    return {
      // equipesNaoParticipantes: [],
      // equipesParticipantes: []
    };
  },

  computed: {
    ...mapGetters({
      getEquipes: "empresa/getEquipes",
      getCampanhasEquipes: "campanha/getCampanhasEquipes"
    })
  },

  watch: {
    dialog(value) {
      return value;
    },

    equipesNaoParticipantes(value) {
      return value;
    },

    equipesParticipantes(value) {
      return value;
    },

    dadosCampanha(value) {
      return value;
    }
  },

  methods: {
    async addTodasEquipes() {
      try {
        let allPost = this.equipesNaoParticipantes.map(item => {
          const form = {
            id_campanha: this.$route.params.idCampanha,
            id_empresa: item.id_empresa,
            id_equipe: item.id_equipe,
            id_funcao: this.dadosCampanha.id_funcao,
            id_indicador: this.dadosCampanha.id_indicador
          };
          return serviceCampanha.postCampanhaEquipe(form);
        });

        await Promise.all(allPost);
        this.$notify({
          type: "success",
          text: `Todas equipes adicionadas na campanha`
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: `Erro ao adicionar equipe`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },
    async removerTodasEquipes() {
      try {
        let allDelete = this.equipesParticipantes.map(item => {
          return serviceCampanha.deleteCampanhaEquipe(item.id_campanha_equipe);
        });
        await Promise.all(allDelete);
        this.$notify({
          type: "warning",
          text: `Todas equipes removidas`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover equipes`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    async addEquipesParcitipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_empresa: item.id_empresa,
          id_equipe: item.id_equipe,
          id_funcao: this.dadosCampanha.id_funcao,
          id_indicador: this.dadosCampanha.id_indicador
        };
        await serviceCampanha.postCampanhaEquipe(form);
        this.$notify({
          type: "success",
          text: `${item.nome_equipe} adicionado na campanha`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: ` Erro ao adicionar  ${item.nome_equipe}`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    async removeEquipesParticipantes(item) {
      try {
        await serviceCampanha.deleteCampanhaEquipe(item.id_campanha_equipe);
        this.$notify({
          type: "warning",
          text: `${item.nome_equipe} removida da campanha`
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover equipe`
        });
      } finally {
        this.$emit("change:fecthEquipes", this.dadosCampanha);
      }
    },

    moveAdded(evt) {
      let equipe = {};
      const { removed } = evt;

      if (removed) {
        equipe = removed.element;
        this.addEquipesParcitipantes(equipe);
      }
    },

    moveRemove(evt) {
      let equipe = {};
      const { removed } = evt;

      if (removed) {
        equipe = removed.element;
        this.removeEquipesParticipantes(equipe);
      }
    },

    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
