var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.funcoes},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_vm._v(" Funções Selecionadas para a Campanha ")])]},proxy:true},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.descricao))+" ")]}},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.possuiMeta)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi mdi-check")]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v(" Não possui metas cadastradas")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.onEdite)?_c('v-btn',{attrs:{"color":"green","dark":"","small":""},on:{"click":function($event){return _vm.cadastrar(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-finance")]),_vm._v(" Cadastrar meta")],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }