<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="1200" persistent>
      <v-card>
        <v-toolbar color="#ECEFF1">
          <v-card-title>
            <span class="headline"
              >Cadastro de Parâmetros de Metas por Público Alvo
            </span>
          </v-card-title>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <!-- Alerta de Erro no Formulário -->
            <v-form ref="formCadastroMeta" v-model="valid">
              <v-row>
                <!-- Público Alvo -->
                <v-col cols="12">
                  <v-input messages="Público Alvo" prepend-icon="mdi-account">
                    {{ form.descricao }}
                  </v-input>
                  <v-divider class="mt-2"></v-divider>
                </v-col>
                <!-- Tipo de Meta -->
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    prepend-inner-icon="mdi-chart-arc"
                    label="Tipo de Meta"
                    :items="tipoMeta"
                    v-model="form.idTipoMeta"
                    :rules="rules.required"
                    item-text="tipo_meta"
                    item-value="id_tipo_meta"
                    @change="
                      fetchBonusMaximo(funcao.id_band, $event);
                      fetchTipoPagamento();
                    "
                  >
                  </v-autocomplete>
                </v-col>
                <!-- Indicadores -->
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    label="Indicador"
                    prepend-inner-icon="mdi-finance"
                    :items="getTextIndicador()"
                    item-text="textIndicador"
                    item-value="id_indicador"
                    v-model="form.idIndicador"
                    required
                    :rules="rulesIndicador"
                    @change="fetchRealizadoEmpresaTrimestre(), verificacao()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    label="Total do Trimestre"
                    prepend-inner-icon="mdi-chart-timeline-variant"
                    v-model="form.quantidade"
                    readonly
                    :loading="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    label="Média Trimestral"
                    prepend-inner-icon="mdi-chart-areaspline"
                    v-model="form.quantidadeMedia"
                    readonly
                    :loading="loading"
                  ></v-text-field>
                </v-col>
                <!-- Tipo de Cálculo ok -->
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    :items="tipoCalculo"
                    v-model="form.idTipoCalculo"
                    label="Tipo de Cálculo"
                    :rules="rules.required"
                    item-text="tipo_calculo"
                    item-value="id_tipo_calculo"
                  ></v-autocomplete>
                </v-col>
                <!-- Base de Cálculo  -->
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    prepend-inner-icon="mdi-calculator-variant"
                    :items="[
                      { text: 'Quantidade', value: 'Q' },
                      { text: 'Valor', value: 'V' }
                    ]"
                    v-model="form.baseCalculo"
                    label="Base de Cálculo"
                    :rules="rules.required"
                  ></v-autocomplete>
                </v-col>
                <!-- Prioridade Indicador -->
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    prepend-inner-icon="mdi-compare-vertical"
                    :items="[
                      { text: 'Maior', value: '>' },
                      { text: 'Menor', value: '<' }
                    ]"
                    label="Prioridade do Indicador"
                    v-model="form.prioridadeIndicador"
                    :rules="rules.required"
                  ></v-autocomplete>
                </v-col>
                <!-- Tipo de Índice -->
                <v-col cols="12" sm="6" md="4">
                  <v-select
                    :items="indice"
                    label="Tipo de Índice"
                    v-model="form.idTipoIndice"
                    item-text="descricao"
                    item-value="id_tipo_indice"
                    :disabled="
                      form.idTipoMeta === 5 ||
                        form.idTipoMeta === 8 ||
                        form.idTipoMeta === 10 ||
                        form.idTipoMeta === 6 ||
                        form.idTipoMeta === 21
                    "
                  ></v-select>
                </v-col>
                <!-- Índices de Crescimento -->
                <v-col cols="12" sm="6" md="4" v-if="form.idTipoIndice != 1">
                  <v-autocomplete
                    :items="getIndicesCrescimentro"
                    item-text="perc_cresc"
                    item-value="id_crescimento"
                    label="Índice de Crescimento"
                    prepend-inner-icon="mdi-percent"
                    v-model="form.idCrescimento"
                    :disabled="
                      form.idTipoMeta === 5 ||
                        form.idTipoMeta === 8 ||
                        form.idTipoIndice == '' ||
                        form.idTipoMeta === 6 ||
                        form.idTipoMeta === 21
                    "
                    clearable
                  >
                    <template v-slot:item="{ item }">
                      {{ item.perc_cresc }}%
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.perc_cresc }}%
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4" v-else>
                  <!-- Quanto o tipo de fexamento for personalizador id 82 -->
                  <v-text-field
                    :disabled="
                      this.form.idTipoMeta === 5 ||
                        this.form.idTipoMeta === 8 ||
                        form.idTipoIndice == ''
                    "
                    :suffix="inputValorMeta.prefix"
                    :name="inputValorMeta.name"
                    :label="inputValorMeta.label"
                    :rules="
                      tipoDado != 'X'
                        ? rules.metaMin
                        : rules.metaMinPersonalizado
                    "
                    v-model="form.valorMeta"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <!-- Valor Bônus -->
                <v-col cols="12" sm="6" md="4">
                  <vuetify-money
                    label="Valor do Bônus"
                    v-model="form.valorBonus"
                    @input="checkBonusMaximo($event)"
                    :error-messages="erroBonusMaximo"
                    :options="options"
                    :disabled="
                      this.form.idTipoMeta === 5 ||
                        this.form.idTipoMeta === 8 ||
                        form.idTipoMeta === 10 ||
                        form.idTipoMeta === 6 ||
                        form.idTipoMeta === 21
                    "
                  ></vuetify-money>
                </v-col>
                <!-- Tipo de Pagamento  -->

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    prepend-inner-icon="mdi-piggy-bank"
                    label="Tipo de Pagamento"
                    :items="tipoPagamento"
                    v-model="form.idTipoPagamento"
                    :rules="rules.required"
                    item-text="tipo_pagamento"
                    item-value="id_tipo_pagamento"
                    :disabled="!form.idTipoMeta"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    label="Base GMR"
                    :items="baseGmr"
                    v-model="form.atrelaGmr"
                    :rules="rules.required"
                    item-text="text"
                    item-value="value"
                    disabled
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <vuetify-money
                    label="Porcentagem GMR"
                    v-model.number="form.porcentagemGMR"
                    :options="optionsPorcentagemGMR"
                    :properties="properties"
                    class="inputHint"
                    readonly
                  ></vuetify-money>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <vuetify-money
                    label="Dobro GMR"
                    v-model.number="form.porcentagemGMRDobro"
                    :options="optionsPorcentagemGMRDobro"
                    :rules="form.porcentagemGMRDobro > 0 ? rules.minDobroGMR : []"
                    ></vuetify-money>
                </v-col>
              </v-row>
              <v-row v-if="!form.porcentagemGMRDobro">
                <v-col cols="12">
                  <v-alert type="warning">
                    Atenção! Se o campo "Dobro GMR" for preenchido o valor do
                    bônus pago será dobrado caso o percentual da meta seja
                    atingido.
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ml-3 white--text"
            dark
            small
            color="success"
            :href="`${publicPath}doc/Campanha_documentacao.pdf`"
            target="_blank"
          >
            <v-icon class="mr-1" color="white">mdi-file-alert-outline</v-icon
            >Documentação
          </v-btn>

          <v-btn
            v-if="this.labelBtnSend == 'Salvar'"
            class="ml-3 white--text"
            dark
            small
            color="orange darken-2"
            @click="openModal()"
          >
            <v-icon class="mr-1" color="white"
              >mdi-file-document-multiple-outline</v-icon
            >Replicar Meta
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="$emit('close')">
            Fechar
          </v-btn>
          <v-btn :disabled="!valid" color="blue darken-1" text @click="send()">
            {{ labelBtnSend }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalReplicarMetas
      :currentFuncao="form.idFuncao == '' ? 0 : form.idFuncao"
      :dialog="dialog"
      @close="dialog = false"
      @save="(funcoesReplicadas = $event), (dialog = false)"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import campanhaService from "@/services/campanha";
import metaService from "@/services/tipoMeta";
import parametrosService from "@/services/parametros";
import campanha from "@/services/http/campanhaService";
import comissao from "@/services/http/comissaoService";
import ModalReplicarMetas from "./ModalReplicarMetas";

export default {
  name: "ModalCadastroIndicadores",

  props: {
    funcao: {
      type: Object
    },
    empresas: {
      type: Array
    }
  },

  components: {
    ModalReplicarMetas
  },

  data() {
    return {
      minMeta: 0,
      publicPath: process.env.BASE_URL,
      dialog: false,
      funcoesReplicadas: "",
      rules: {
        required: [v => !!v || "Obrigatório"],
        metaMin: [
          value =>
            value >= parseFloat(this.minMeta) ||
            `O valor da meta deve ser maior que ${this.minMeta - 1}`
        ],
        metaMinPersonalizado: [
          value => value > 0 || `O valor da meta deve ser maior que 0`
        ],
        minDobroGMR: [
          value => this.checkDobroGMR(value) || 'O valor informado deve ser maior que a Porcentagem GMR'
        ]
      },
      indicador: "",
      indice: [],
      baseGmr: [
        { text: "Sim", value: "S" },
        { text: "Não", value: "N" }
      ],
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      },
      erroBonusMaximo: null,
      optionsPorcentagemGMR: {
        locale: "pt-BR",
        prefix: "%",
        length: 4,
        precision: 2
      },
      optionsPorcentagemGMRDobro: {
        locale: "pt-BR",
        prefix: "%",
        length: 4,
        precision: 2
      },
      valid: false,
      labelBtnSend: "",
      methodSend: "",
      show: false,
      tipoDado: "",
      tipoMeta: [],
      tipoCalculo: [],
      baseCalculo: [],
      prioridadeIndicador: [],
      tipoPagamento: [],
      info: [],
      bonificacaoMaxima: null,

      form: {
        descricao: "",
        idCampanha: "",
        idIndicador: "",
        idFuncao: "",
        idTipoCalculo: "",
        prioridadeIndicador: ">",
        idTipoMeta: "",
        baseCalculo: "",
        idCrescimento: "",
        valorMeta: "",
        valorBonus: "",
        idTipoPagamento: "",
        idCampanhaFuncao: "",
        idTipoIndice: "",
        atrelaGmr: "",
        porcentagemGMR: 0,
        porcentagemGMRDobro: 0.0,
        quantidade: 0,
        quantidadeMedia: 0
      },
      properties: {
        hint: "Esse campo não pode ser alterado"
      },
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      getIndicadores: "campanha/getIndicadores",
      getIndicesCrescimentro: "campanha/getIndicesCrescimento",
      getMetasCadastradas: "campanha/getMetasCadastradas"
    }),

    checkIsMetaProgressiva() {
      if (this.form.idTipoMeta === 5) return true;
      return false;
    },

    rulesIndicador() {
      if (Object.keys(this.funcao).length) {
        let index = this.getMetasCadastradas.indexOf(this.funcao);
        let result = this.getMetasCadastradas.some((element, key) => {
          return (
            element.id_funcao == this.form.idFuncao &&
            element.id_indicador == this.form.idIndicador &&
            index != key
          );
        });

        return [
          !result || "O indicador já esta cadastrado para essa função",
          v => !!v || "Obrigatório"
        ];
      }
      return [true];
    },

    inputValorMeta() {
      if (this.form.baseCalculo == "V") {
        return {
          prefix: "R$",
          label: "Valor Meta"
        };
      }
      return {
        prefix: "UN",
        label: "Quantidade da Meta"
      };
    }
  },

  watch: {
    funcao(newValue) {
      if (this.methodSend == "putCampanhaFuncao") {
        this.$refs?.formCadastroMeta?.resetValidation();
        this.form.descricao = newValue.funcao || newValue.descricao;
        this.form.idCampanha = parseInt(newValue.id_campanha);
        this.form.idFuncao = parseInt(newValue.id_funcao);
        this.form.idIndicador = parseInt(newValue.id_indicador);
        this.form.idTipoCalculo = parseInt(newValue.id_tipo_calculo);
        this.form.prioridadeIndicador = newValue.maior_menor;
        this.form.idTipoMeta = parseInt(newValue.id_tipo_meta);
        this.form.baseCalculo = newValue.base_calculo;
        this.form.idCrescimento = parseInt(newValue.id_crescimento);
        this.form.valorMeta = parseFloat(newValue.valor_meta);
        this.form.valorBonus = parseFloat(newValue.valor_bonus);
        this.form.idTipoPagamento = newValue.id_tipo_pgto;
        this.form.idCampanhaFuncao = parseInt(newValue.id_campanha_funcao);
        this.form.idTipoIndice = parseInt(newValue.id_tipo_indice);
        this.form.atrelaGmr = newValue.atrela_gmr;
        this.form.porcentagemGMR = parseFloat(newValue.pec_gmr);
        this.form.porcentagemGMRDobro = parseFloat(newValue.pec_gmr_dobro);
      } else {
        this.form.descricao = newValue.funcao || newValue.descricao;
        this.form.idFuncao = parseInt(newValue.id_funcao);
      }
    },

    ["form.idTipoMeta"](newValue) {
      if (newValue === 5) {
        this.form.valorBonus = "";
        this.form.idCrescimento = "";
      }
    },

    ["form.idCrescimento"](newValue) {
      if (newValue) {
        this.form.valorMeta = null;
      }
    },

    ["form.valorMeta"](newValue) {
      if (newValue) {
        this.form.idCrescimento = "";
      }
    },
    async ["form.idIndicador"]() {
      if (this.show != false) await this.fetchRealizadoEmpresaTrimestre();
      await this.fetchMetas();
    },

    show() {
      this.getPercentage();
    }
  },

  async mounted() {
    await this.fetchCampanha();
    this.fetchTipoCalculo();
    this.fetchTipoIndice();
    this.fetchTipoMetas();
  },

  methods: {
    ...mapActions({
      fetchIndicadores: "campanha/fetchIndicadores",
      fetchIndicesCrescimento: "campanha/fetchIndicesCrescimento"
    }),

    checkDobroGMR(value) {
      if (parseFloat(value.replace(",", ".")) === parseFloat(this.form.porcentagemGMR)) return false;
      if (parseFloat(value.replace(",", ".")) < parseFloat(this.form.porcentagemGMR)) return false;
      return true;
    },
    resetValidation() {
      this.$refs.formCadastroMeta.resetValidation();
    },
    send() {
      this[this.methodSend]();
    },
    getTextIndicador() {
      return this.getIndicadores.map(item => {
        item["textIndicador"] = `${item.id_indicador} - ${item.indicador}`;
        return item;
      });
    },
    async fetchRealizadoEmpresaTrimestre() {
      this.loading = true;
      let empresas = await this.getEmpresasCampanha();
      let response = await comissao()
        .realizadoEmpresaTrimestre()
        .show({
          empresas,
          id_indicador: this.form.idIndicador,
          id_tipo_dado: this.tipoDado
        });
      this.form.quantidade = response.data.qtde || 0;
      this.form.quantidadeMedia = response.data.qtd_media || 0;
      this.loading = false;
    },

    async getEmpresasCampanha() {
      let {
        data: { data }
      } = await campanhaService.getEmpresasParticipantes({
        id_campanha: this.$route.params.idCampanha
      });

      let idEmpresas = [];

      data.map(item => {
        if (item.id_empresa) {
          idEmpresas.push(item.id_empresa);
        }
      });
      return idEmpresas;
    },

    async verificacao() {
      this.getIndicadores.map(item => {
        if (item.id_indicador === this.form.idIndicador) {
          this.form.atrelaGmr = item.gmr_campanha;
        }
      });
    },

    async fetchCampanha() {
      let {
        data: { data }
      } = await campanhaService.getEmpresasParticipantes({
        id_campanha: this.$route.params.idCampanha
      });

      data.map(item => {
        this.tipoDado = item.id_tipo_dado;
      });
    },

    async getPercentage() {
      let idCampanha = this.$route.params.idCampanha;
      let { data } = await campanha(idCampanha).show();
      this.form.porcentagemGMR = data.perc_crescimento;
    },

    async fetchTipoIndice() {
      try {
        const { data } = await parametrosService.getTipoIndice();
        this.indice = data.data;
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          text: "Erro eo buscar indices"
        });
      }
    },
    async fetchMetas() {
      try {
        if (!this.form.valorMeta) {
          const { data } = await comissao()
            .metaFuncao()
            .show({
              id_funcao: this.funcao.id_funcao,
              id_tipo_meta: 2,
              id_indicador: this.form.idIndicador
            });
          this.minMeta = data?.data[0]?.meta_valor || 0;
          this.form.valorMeta = this.minMeta;
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          text: "Erro eo buscar valores de metas"
        });
      }
    },

    async putCampanhaFuncao() {
      try {
        const apiObjForm = {
          id_campanha: this.$route.params.idCampanha,
          id_indicador: this.form.idIndicador,
          id_funcao: this.form.idFuncao,
          id_tipo_calculo: this.form.idTipoCalculo,
          maior_menor: this.form.prioridadeIndicador,
          id_tipo_meta: this.form.idTipoMeta,
          base_calculo: this.form.baseCalculo,
          id_crescimento: this.form.idCrescimento,
          valor_bonus: this.form.valorBonus,
          valor_meta: this.form.valorMeta,
          id_tipo_pgto: this.form.idTipoPagamento,
          id_tipo_indice: this.form.idTipoIndice,
          atrela_gmr: this.form.atrelaGmr,
          pec_gmr: this.form.porcentagemGMR,
          pec_gmr_dobro: this.form.porcentagemGMRDobro
        };
        await campanhaService.putCampanhaFuncao(
          this.form.idCampanhaFuncao,
          apiObjForm
        );
        this.$emit("campanhaFuncaoCadastrada");
        this.$notify({
          text: "Alterações salvas com sucesso",
          type: "success"
        });
      } catch (error) {
        this.$notify({
          text: "Erro ao efeturar alterações",
          type: "error"
        });
      }
    },

    async postCampanhaFuncao() {
      try {
        const apiObjForm = this.constructObject();

        await campanhaService.postCampanhaFuncao(apiObjForm); // cria funcao da campanha
        this.$notify({
          text: "Publico alvo cadastrado com sucesso",
          type: "success"
        });

        if (this.funcoesReplicadas) {
          let allPost = this.funcoesReplicadas.map(item => {
            apiObjForm.id_funcao = item.id_funcao;
            return campanhaService.postCampanhaFuncao(apiObjForm);
          });
          await Promise.all(allPost);
          this.$notify({
            type: "success",
            text: `Publico alvo replicado com sucesso`
          });
        }
        this.$emit("campanhaFuncaoCadastrada");
      } catch (error) {
        this.$notify({
          text: "Erro ao cadastrar publico alvo",
          type: "error"
        });
      }
    },

    constructObject() {
      const objForm = {
        id_campanha: this.$route.params.idCampanha,
        id_indicador: this.form.idIndicador,
        id_funcao: this.form.idFuncao,
        id_tipo_calculo: this.form.idTipoCalculo,
        maior_menor: this.form.prioridadeIndicador,
        id_tipo_meta: this.form.idTipoMeta,
        base_calculo: this.form.baseCalculo,
        id_crescimento: this.form.idCrescimento,
        valor_meta: this.form.valorMeta,
        valor_bonus: this.form.valorBonus,
        id_tipo_pgto: this.form.idTipoPagamento,
        id_tipo_indice: this.form.idTipoIndice,
        atrela_gmr: this.form.atrelaGmr,
        pec_gmr: this.form.porcentagemGMR,
        pec_gmr_dobro: this.form.porcentagemGMRDobro || 0
      };

      if (this.form.idTipoMeta == 5) {
        delete objForm.id_crescimento;
        delete objForm.valor_bonus;
      }

      if (!this.form.idCrescimento) delete objForm.id_crescimento;
      if (!this.form.valorBonus) delete objForm.valor_bonus;

      return objForm;
    },

    async fetchTipoMetas() {
      try {
        const { data } = await metaService.getTipoMeta({
          tipo_metas: "S"
        });

        this.tipoMeta = data.data.sort((a, b) =>
          a.id_tipo_meta > b.id_tipo_meta ? 1 : -1
        );

        if(this.tipoDado == 'C') {
          const excludedTipoMeta = [5, 6, 7, 10, 21, 181];
          this.tipoMeta = this.tipoMeta.filter(item => !excludedTipoMeta.includes(item.id_tipo_meta));
        }
      } catch (error) {
        this.$notify({
          text: "Erro ao buscar as metas",
          type: "error"
        });
      }
    },

    async fetchTipoPagamento() {
      try {
        const { data } = await parametrosService.getMetaTipoPagamento({
          id_tipo_meta: this.form.idTipoMeta || null,
          status: "S"
        });

        this.tipoPagamento = data.data.sort((a, b) => {
          if (a.id_tipo_meta > b.id_tipo_meta) return 1;
          if (a.id_tipo_meta < b.id_tipo_meta) return -1;
        });
      } catch (error) {
        this.$notify({
          text: "Erro ao buscar as metas",
          type: "error"
        });
      }
    },

    async fetchTipoCalculo() {
      try {
        const { data } = await parametrosService.getTipoDeCalculo({
          status: "S"
        });

        this.tipoCalculo = data.data;
      } catch (error) {
        this.$notify({
          text: "Erro ao buscar o Tipo de Cálculo",
          type: "error"
        });
      }
    },

    async fetchBonusMaximo(id_band, id_tipo_meta) {
      try {
        this.erroBonusMaximo = null;
        const { data } = await campanhaService.getBonificacaoMaxima({
          id_band,
          id_tipo_meta
        });
        this.bonificacaoMaxima = data.data[0];
      } catch (error) {
        console.error(error);
        this.$notify({
          text: "Erro ao buscar o Bonificação Máxima",
          type: "error"
        });
      }
    },

    checkBonusMaximo(valor) {
      if (valor > parseFloat(this.bonificacaoMaxima?.valor_max)) {
        const valueCurrency = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL"
        }).format(this.bonificacaoMaxima?.valor_max);
        this.erroBonusMaximo = `Bonificação Limite é de ${valueCurrency}`;
      } else {
        this.erroBonusMaximo = null;
      }
    },

    openModal() {
      this.currentFuncao = Object.assign({}, this.currentFuncao);
      this.dialog = true;
    }
  },

  async created() {
    await this.fetchTipoPagamento();
    this.fetchIndicadores({ per_page: -1, ativa_campanha: "S" });
    this.fetchIndicesCrescimento({ per_page: -1 });
  }
};
</script>
<style>
.inputHint .v-messages__message {
  color: red !important;
}
</style>
