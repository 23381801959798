<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-toolbar color="#ECEFF1">
          <v-card-title>
            <span class="headline">Replicar Metas</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-form ref="form">
              <v-row>
                <v-col cols="12">
                  <v-select
                    :items="funcoes"
                    v-model="idFuncao"
                    label="Selecione as Funções"
                    item-text="descricao"
                    item-value="id_funcao"
                    multiple
                    no-data-text="Nenhuma Função Encontrada"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.funcao }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ idFuncao.length - 1 }})
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="close()">
            Fechar
          </v-btn>
          <v-btn
            :disabled="idFuncao != '' ? false : true"
            color="primary"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    currentFuncao: {
      type: Number
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      idFuncao: "",
      funcoes: []
    };
  },

  computed: {
    ...mapGetters({
      getFuncoesPublicoAlvoSelecionado: "campanha/getPublicoAlvoSelecionado"
    })
  },

  watch: {
    currentFuncao(value) {
      this.getFuncoes(value);
    }
  },

  methods: {
    getFuncoes(idFuncao) {
      this.funcoes = this.getFuncoesPublicoAlvoSelecionado.filter(
        item => item.id_funcao != idFuncao
      );
    },
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },
    save() {
      this.$emit("save", this.funcoes);
      this.close();
    }
  }
};
</script>
