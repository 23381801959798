<template>
  <v-dialog v-model="dialog" max-width="60%" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">
          Vincular Colaborador -
          <strong>{{ campanha.descricao_modelo }}</strong>
        </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Colaboradores
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addTodasFaixas"
              :disabled="colaboradoresNaoParticipantesProgressiva.length == 0"
            >
              Adicionar todos
            </v-btn>
            <draggable
              class="draggable-area exclude-persons"
              :list="colaboradoresNaoParticipantesProgressiva"
              group="colaboradores"
              @change="moveAdded"
            >
              <div
                class="v-list-item__content list"
                v-for="(colaborador,
                index) in colaboradoresNaoParticipantesProgressiva"
                :key="index"
              >
                <div class="list-colaborador-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>
                      {{ colaborador.nome || colaborador.colaborador }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                  </div>
                  <div class="mr-4">
                    <v-btn
                      icon
                      @click="addFaixa(colaborador)"
                      :disabled="!formIsValid"
                    >
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Colaboradores
              Com Faixa
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removerTodasFaixas"
              :disabled="colaboradoresParticipantesProgressiva.length == 0"
            >
              Remover todos
            </v-btn>
            <draggable
              class="draggable-area include-persons"
              :list="colaboradoresParticipantesProgressiva"
              group="colaboradores"
              @change="moveRemove"
            >
              <div
                class="v-list-item__content"
                v-for="(colaborador,
                index) in colaboradoresParticipantesProgressiva"
                :key="index"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn class="mr-4" icon @click="removeFaixa(colaborador)">
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${colaborador.cod_funcao} -`
                      }}</strong>

                      {{ colaborador.nome }}
                    </v-row>
                    <BaseSegmento :segmento="colaborador.id_band" />
                    De: {{ colaborador.qtde_de }} Até:
                    {{ colaborador.qtde_ate }} Bônus:
                    {{ colaborador.valor_bonus }}
                  </div>
                </div>
              </div>
            </draggable>
            <v-divider class="mt-5 mb-5"></v-divider>

            <v-row dense align="center">
              <v-col cols="12">
                <FormMetaProgressivaModelo
                  @formIsValid="formIsValid = $event"
                  @form="form = $event"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import FormMetaProgressivaModelo from "./FormMetaProgressivaModelo";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import serviceParametro from "@/services/parametros";
import serviceCampanhas from "@/services/campanha";

export default {
  name: "DraggableColaboradorCampanhaModeloProgressiva",

  components: {
    draggable,
    BaseSegmento,
    FormMetaProgressivaModelo
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    campanha: {
      type: Object
    },

    colaboradoresNaoParticipantesProgressiva: {
      type: Array
    },

    colaboradoresParticipantesProgressiva: {
      type: Array
    }
  },

  data() {
    return {
      formIsValid: false,
      form: {}
    };
  },

  computed: {
    ...mapGetters({
      getCurrentCampanha: "campanha/getCurrentCampanha"
    }),

    optionsVuetifyMoney() {
      return {
        locale: "pt-BR",
        prefix: "R$",
        length: 3,
        precision: 2
      };
    }
  },

  methods: {
    async addTodasFaixas() {
      const promises = this.colaboradoresNaoParticipantesProgressiva.map(
        async item => {
          const { data } = await serviceCampanhas.getCampanhaFuncao({
            id_campanha: this.$route.params.idCampanha,
            id_funcao: item.id_funcao,
            id_indicador: this.campanha.id_indicador,
            id_band: this.campanha.id_band
          });
          const form = {
            id_campanha: this.$route.params.idCampanha,
            id_funcao: item.id_funcao,
            id_colaborador: item.id_colaborador,
            id_indicador: this.campanha.id_indicador,
            valor_bonus: this.form.valorBonus,
            id_produto: this.campanha.id_produto,
            id_tipo_indice: 3,
            id_campanha_funcao: data.data[0].id_campanha_funcao,
            qtde_de: this.form.quantidadeDe,
            qtde_ate: this.form.quantidadeAte
          };
          serviceParametro.postMetaModeloColaboradorProgressiva(form);
          this.$notify({
            type: "success",
            text: `Faixas adicionadas`
          });
        }
      );

      Promise.all(promises)
        .then(() => this.refreshAdd())
        .catch(error => {
          console.error(error);
          this.$notify({
            type: "error",
            text: "Erro ao adicionar faixas"
          });
        });
    },

    async addFaixa(item) {
      try {
        const { data } = await serviceCampanhas.getCampanhaFuncao({
          id_campanha: this.$route.params.idCampanha,
          id_funcao: item.id_funcao,
          id_indicador: this.campanha.id_indicador,
          id_band: this.campanha.id_band
        });
        const form = {
          id_campanha: this.$route.params.idCampanha,
          id_funcao: item.id_funcao,
          id_colaborador: item.id_colaborador,
          id_indicador: this.campanha.id_indicador,
          valor_bonus: this.form.valorBonus,
          id_produto: this.campanha.id_produto,
          id_tipo_indice: 3,
          id_campanha_funcao: data.data[0].id_campanha_funcao,
          qtde_de: this.form.quantidadeDe,
          qtde_ate: this.form.quantidadeAte
        };
        await serviceParametro.postMetaModeloColaboradorProgressiva(form);
        this.$notify({
          type: "success",
          text: `Faixa adicionada para ${item.colaborador}`
        });

        this.refreshAdd();
      } catch (error) {
        if (error.response.status == 422) {
          this.$notify({
            type: "error",
            text: error.response.data.qtde_de[0]
          });
        } else {
          this.$notify({
            type: "error",
            text: `Erro ao adicionar faixa para ${item.colaborador}`
          });
        }
      }
    },

    removerTodasFaixas() {
      const promises = this.colaboradoresParticipantesProgressiva.map(item =>
        serviceParametro.deleteMetaModeloColaboradorProgressiva(
          item.id_meta_modelo
        )
      );
      Promise.all(promises)
        .then(() => {
          this.$notify({
            type: "warning",
            text: "Faixas removidas"
          });
          this.refreshRemove();
        })
        .catch(error => {
          console.log(error);
          this.$notify({
            type: "error",
            text: "Erro ao remover faixas"
          });
        });
    },

    async removeFaixa(item) {
      try {
        await serviceParametro.deleteMetaModeloColaboradorProgressiva(
          item.id_meta_modelo
        );

        this.$notify({
          type: "warning",
          text: `Faixa removida para ${item.nome}`
        });
        this.refreshRemove();
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Error ao remover faixa para ${item.nome}`
        });
      }
    },

    moveAdded(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.addFaixa(colaborador);
      }
    },

    moveRemove(evt) {
      let colaborador = {};
      const { removed } = evt;

      if (removed) {
        colaborador = removed.element;
        this.removeFaixa(colaborador);
      }
    },

    close() {
      this.$emit("close");
    },

    refreshAdd() {
      this.$emit("atualizaColaboradoresParticipantesProgressiva");
    },

    refreshRemove() {
      this.$emit("atualizaColaboradoresParticipantesProgressiva");
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-persons {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-persons {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-colaborador-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
